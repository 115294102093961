
import { Options, Vue } from "vue-class-component";
import base64 from "base-64";
import QRCode from "qrcode";

@Options({
  async created() {
    let query = this.$route.query;
    this.code = query.code;
  },

  watch: {
    code: async function (val) {
      if (!this.code) {
        return;
      }
      try {
        let code: string;
        if (this.code.startsWith("N")) {
          code = this.code;
        } else {
          code = base64.decode(this.code);
        }

        let qrcodeImgSrc = await QRCode.toDataURL(code, { version: 5 });
        this.qrcodeImgSrc = qrcodeImgSrc;
      } catch (err) {
        console.error(err);
      }
    },
  },

  data() {
    return {
      code: "",
      qrcodeImgSrc: "",
    };
  },
})
export default class Home extends Vue {}
